import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
	Content,
	Grid
} from '../components/styled'

import {X, Check} from 'styled-icons/boxicons-regular/'

const RTable = styled(Grid).attrs(props => ({
  className: `${props.className} flex-wrap-l nb5`
}))``

RTable.Cell = styled(Grid.Col).attrs(props => ({
  className: `${props.className} w-50-l`
}))``

const Item = styled(Content).attrs(props => ({
  className: `${props.className/*.replace('f5', 'f4')*/} relative h-100 pv2 pl2 pr4 bb b--moon-gray`,
}))`
  ${RTable.Cell}.is-last > & {
    border-bottom: none;
  }
`

const Icon = styled.svg.attrs(props => ({
  className: `absolute top-0 bottom-0 right-0 w2 h2 v-mid ${(props.negative && 'red') || (props.positive && 'green')}`
}))`
  &&& {
    top: 50%;
    transform: translateY(-50%);
  }
`

const Comparison = ({cols}) => (
  <RTable>
    {cols.map((col, colIndex) =>
      col.map((cell, cellIndex) =>
        <RTable.Cell className={`order-${cellIndex+1} ${col.length===(cellIndex+1) && 'mb5 is-last'}`} key={`${colIndex}-${cellIndex}`}>{cell}</RTable.Cell>
      )
    )}
  </RTable>
)

Comparison.Item = ({children, positive, negative}) => (
  <Item className={(negative || positive) && 'pr4'}> {children} {(negative && <Icon as={X} negative />) || (positive && <Icon as={Check} positive />)}</Item>
)

Comparison.propTypes = {

}

Comparison.defaultProps = {
}

export default Comparison