import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
	Container,
	Title,
	Content,
	Button,
  Grid,
  //Text
} from '../components/styled'
import Quote from '../components/Quote'
import Contact from '../components/Contact'
import Comparison from '../components/Comparison'

import {SmileySad, SmileyHappy} from 'styled-icons/boxicons-solid/'

const Icon = styled.svg.attrs(props => ({
  className: `db center h4 v-mid ${(props.negative && 'red') || (props.positive && 'green')}`
}))`
`

export default ({ data }) => {
  //console.log(data)
  return (
  <Layout>
    <SEO
      title="Landingpage für mehr Umsatz - Landingpages vom Profi aus einer Hand"
      description="► Optimierte Landingpage: ✓ Bietet dem Besucher einen Nutzen ✓ Starke Call-to-action ✓ Responsive ✓ Profi aus Köln / Bonn ► Online-Marketing rentabler machen!"
    />
  	<Container>
  		<Container.Inner>
  			<Content>Landingpage</Content>
		    <Title as="h1">Erhöhen Sie Schritt für Schritt den Umsatz, den Sie mit Ihrer Landingpage erzielen</Title>
		    <Content big>
		    	<p>Eine gute Landingpage weckt das Interesse Ihrer Zielgruppe, schafft Vertrauen bei potenziellen Kunden und wandelt jeden Euro, den Sie in Online-Kampagnen stecken, in mehr Geld.</p>
          <Button as={Link} primary="true" to="/kontakt/">Landingpage anfragen</Button>
		    </Content>
		  </Container.Inner>
	  </Container>
     <Container>
      <Container.Inner>
        <Comparison cols={[
          [
            <Title smaller>Nicht optimierte Landingpage</Title>,
            <Icon as={SmileySad} negative />,
            <Comparison.Item negative>Bietet dem Besucher keinen Nutzen</Comparison.Item>,
            <Comparison.Item negative>Schwache Call-to-action</Comparison.Item>,
            <Comparison.Item negative>Conversion-Elemente fehlen</Comparison.Item>,
            <Comparison.Item negative>Fehlerhaftes oder nicht nutzerfreundliches Formular</Comparison.Item>,
            <Comparison.Item negative>Nur für Desktop optimiert</Comparison.Item>,
            <Comparison.Item negative>Langsame Ladezeit</Comparison.Item>
          ],
          [
            <Title smaller>Optimierte Landingpage</Title>,
            <Icon as={SmileyHappy} positive />,
            <Comparison.Item positive>Bietet dem Besucher einen Nutzen</Comparison.Item>,
            <Comparison.Item positive>Starke Call-to-action</Comparison.Item>,
            <Comparison.Item positive>Conversion-Elemente vorhanden</Comparison.Item>,
            <Comparison.Item positive>Fehlerfreies und nutzerfreundliches Formular</Comparison.Item>,
            <Comparison.Item positive>Für Desktop, Tablet und Mobile optimiert (Responsive)</Comparison.Item>,
            <Comparison.Item positive>Schnelle Ladezeit, auch unterwegs</Comparison.Item>
          ]
        ]} />
      </Container.Inner>
    </Container>
    <Container>
      <Container.Inner>
        <Quote {...data.allTestimonialsYaml.edges.find(el => el.node.name === 'anwaltverlag').node} />
      </Container.Inner>
    </Container>
	  <Container>
  		<Container.Inner>
        <Grid className="nb5 flex-wrap-l">
          <Grid.Col className="mb5 w-50-l">
            <Title smaller>Landingpage aus einer Hand</Title>
            <Content>
              <p>Konzept, Text & Grafik und Programmierung kommen bei mir „aus einer Hand“. Ich biete Ihnen die Erstellung einer Landingpage, mehrerer Landingpage-Varianten oder laufende Conversion Rate Optimierung (CRO).</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-50-l">
            <Title smaller>Eine gute Landingpage steht nie still</Title>
            <Content>
              <p>Stetiges Testen der Landingpage erhöht den Erfolg Ihrer Online-Marketing-Maßnahmen. Durch A/B-Tests lassen sich Schritt für Schritt die Elemente ermitteln, die zum Erfolg führen.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-50-l">
            <Title smaller>Der Kontext ist entscheidend</Title>
            <Content>
              <p>Eine Landingpage sollte immer auf die jeweilige Kampagne abgestimmt sein. Das geht soweit, das teilweise einzelne Varianten für Anzeigengruppen sinnvoll sind.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-50-l">
            <Title smaller>Eine schlechte Landingpage ist eine teure Landingpage</Title>
            <Content>
              <p>Wenn Sie auf eine professionelle Landingpage verzichten oder diese nicht laufend verbessern, kommt Ihnen das teuer zu stehen. Je besser die Landingpage, umso rentabler das gesamte Online-Marketing, das auf diese Seite zeigt.</p>
            </Content>
          </Grid.Col>
        </Grid>
  		</Container.Inner>
  	</Container>
    <Contact>
      <Title center>Machen Sie jetzt Ihr Online-Marketing noch rentabler, mit einer Landingpage aus einer Hand</Title>
      <Content big center>
        <p>Lassen Sie Ihre Landingpage optimieren, steigern Sie Schritt für Schritt die Conversion Rate und den Umsatz Ihres Unternehmens.</p>
        <Button as={Link} primary="true" to="/kontakt/">Landingpage anfragen</Button>
      </Content>
    </Contact>
  </Layout>
)}

export const query = graphql`
  query {
    allTestimonialsYaml(filter: {name: {in: [ "anwaltverlag"]}}) {
      edges {
        node {
          name
          children: content
          source
          image {
            ...rectangleImage
          }
          logo {
            ...logo
          }
        }
      }
    }
  }
`
